import request from '@/utils/request'
// 查询客户列表
export function getCustoms (data) {
    return request({
        url: '/callcenter/callCenterTaskCustomer/centerCustomsStatic',
        method: 'post',
        data,
    })
}

//新增客户跟进
export function saveCustomsProcessor (data) {
    return request({
        url: '/callcenter/callCenterCustomerProcess/saveCustomsProcessor',
        method: 'post',
        data,
    })
}

//获取通讯记录
export function selByUserProcess (data) {
    return request({
        url: '/callcenter/callCenterCustomerProcess/selByUserProcess',
        method: 'post',
        data,
    })
}

//获取子账号列表
export function getSonAccountLists (data) {
    return request({
        url: '/callcenter/callCenterUser/getSonAccountLists',
        method: 'get',
        params: data
    })
}

//主账号分配客户
export function distCustoms (data) {
    return request({
        url: '/callcenter/callCenterTaskCustomer/distCustoms',
        method: 'post',
        data
    })
}


//获取所有国家
export function getStateInfo () {
    return request({
        url: '/callcenter/stateInfoTable/getStateInfo',
        method: 'get',
    })
}

//获取所有展会任务
export function getExhibitionTaskLists () {
    return request({
        url: '/callcenter/callCenterExhibitionTask/getExhibitionTaskLists',
        method: 'get',
    })
}

//全选客户
export function checkAll (data) {
    return request({
        url: '/callcenter/callCenterTaskCustomer/queryAllCustomerIds',
        method: 'POST',
        data
    })
}

//根据id查询客户详情
export function getCenterTaskCustomerDetail (data) {
    return request({
        url: '/callcenter/callCenterTaskCustomer/getCenterTaskCustomerDetail',
        method: 'get',
        params: data
    })
}

//编辑客户端数据信息
export function editCustomsInfo (data) {
    return request({
        url: '/callcenter/callCenterTaskCustomer/editCustomsInfo',
        method: 'POST',
        data
    })
}


//客户数据描述列表查询
export function getDataDesc () {
    return request({
        url: '/callcenter/callCenterTaskCustomer/centerCustomsDataDesc',
        method: 'get'
    })
}

