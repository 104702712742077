<template>
  <div id="customer">
    <div class="cc-list-content">
      <div class="cc-search-wrap">
        <a-form
          :model="searchForm"
          name="basic"
          layout="vertical"
          autoComplete="off"
          ref="searchFormRef"
        >
          <a-form-item label="Company Name" name="companyName">
            <a-input
              v-model:value="searchForm.companyName"
              placeholder="Please Enter"
            />
          </a-form-item>
          <a-form-item label="Country/Region" name="areaCode">
            <a-select
              optionFilterProp="option"
              show-search
              :filter-option="filterOption"
              v-model:value="searchForm.areaCode"
              placeholder="Please Select"
              allowClear
            >
              <a-select-option
                v-for="item in stateList"
                :option="item.englishName"
                :key="item.id"
                :value="item.acronym"
                >{{ item.englishName }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="Tags" name="flagIds">
            <a-select
              mode="multiple"
              v-model:value="searchForm.flagIds"
              placeholder="Please Select"
              allowClear
            >
              <a-select-option
                v-for="item in tagsListAll"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="Mobile Number" name="phone">
            <a-input
              v-model:value="searchForm.phone"
              placeholder="Please Enter"
            />
          </a-form-item>
          <a-form-item label="Exhibitions" name="exhibitionTaskId">
            <a-select
              v-model:value="searchForm.exhibitionTaskId"
              placeholder="Please Select"
              allowClear
            >
              <a-select-option
                v-for="item in exhibitionTaskList"
                :key="item.id"
                :value="item.id"
                >{{ item.exhibitionNameEn }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="Data Description" name="dataDesc">
            <a-select
              v-model:value="searchForm.dataDesc"
              placeholder="Please Select"
              allowClear
            >
              <a-select-option
                v-for="item in dataDescList"
                :key="item"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="Dead Number" name="validFlag">
            <a-select
              v-model:value="searchForm.validFlag"
              placeholder="Please Select"
              allowClear
            >
              <a-select-option :value="1">Yes</a-select-option>
              <a-select-option :value="0">No</a-select-option>
            </a-select>
          </a-form-item>
          <template v-if="isOpen">
            <a-form-item label="Followed Up" name="isFollowUp">
              <a-select
                v-model:value="searchForm.isFollowUp"
                placeholder="Please Select"
                allowClear
              >
                <a-select-option :value="1">Followed up</a-select-option>
                <a-select-option :value="0">Not followed up</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              v-if="role == 0"
              label="Client Affiliation"
              name="belongUserId"
            >
              <a-select
                v-model:value="searchForm.belongUserId"
                placeholder="Please Select"
                allowClear
              >
                <a-select-option
                  v-for="item in accountList"
                  :key="item.id"
                  :value="item.id"
                  >{{ `${item.firstName} ${item.lastName}` }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="Registered" name="registeredFlag">
              <a-select
                v-model:value="searchForm.registeredFlag"
                placeholder="Please Select"
                allowClear
              >
                <a-select-option :value="1">Yes</a-select-option>
                <a-select-option :value="0">No</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Number Belongs to Buyer Himself" name="selfFlag">
              <a-select
                v-model:value="searchForm.selfFlag"
                placeholder="Please Select"
                allowClear
              >
                <a-select-option :value="1">Yes</a-select-option>
                <a-select-option :value="0">No</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Follow-up Status" name="followUpStatus">
              <a-select
                v-model:value="searchForm.followUpStatus"
                placeholder="Please Select"
                allowClear
              >
                <a-select-option :value="1">end follow-up</a-select-option>
                <a-select-option :value="0">continue follow-up</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Date Range For Calls" name="timeRange">
              <a-range-picker
                v-model:value="searchForm.timeRange"
                style="width: 320px"
                @change="handleDate"
              ></a-range-picker>
            </a-form-item>
            <a-form-item label="Call Count">
              <a-row>
                <a-col :span="11">
                  <a-input-number id="inputNumber" v-model:value="searchForm.callCountMin"  :min="0"  style="width:120px"/>
                </a-col>
                <a-col :span="2" >
                  <span>-</span>
                </a-col>
                <a-col :span="11" >
                  <a-input-number id="inputNumber" v-model:value="searchForm.callCountMax" :min="0"  style="width:120px" />
                </a-col>
              </a-row>
            </a-form-item>
          </template>

          <a-form-item style="margin: 0 36px 16px">
            <a-button type="primary" htmlType="submit" @click="getAllData"
              >Search</a-button
            >
            <a-button style="margin-left: 20px" @click="reset">Reset</a-button>
            <a-button @click="isOpen = !isOpen" type="link"
              >{{ isOpen ? 'Fold' : 'More' }}
              <up-outlined v-if="isOpen" />
              <down-outlined v-else />
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div v-if="role == 0" class="assign">
        <div>
          <a-button
            type="primary"
            @click="checkAllData"
            :loading="resetLoading"
            :disabled="!tableData.length"
            >Select All</a-button
          >
          <a-button @click="selectIds = []" style="margin-left: 15px"
            >Clear Selected</a-button
          >
        </div>
        <div>
          <a-button
            @click="showAccountVisible"
            :disabled="selectIds.length <= 0"
            style="margin-right: 15px"
            type="primary"
            >Assign Client</a-button
          >
          <a-popconfirm
            :disabled="selectIds.length <= 0"
            placement="top"
            ok-text="Yes"
            cancel-text="No"
            @confirm="accountOk(2)"
          >
            <template #title>
              <p>Are you sure to recycle client ?</p>
            </template>
            <a-button
              :disabled="selectIds.length <= 0"
              style="margin-right: 15px"
              >Recycle Client</a-button
            >
          </a-popconfirm>
          <a style="margin: 0 15px 0 10px" :href="importUrl">Download Excel Template</a>
          <a-button
            @click="isImport = true"
            style="margin-right: 15px"
            type="link"
            >Upload Excel</a-button
          >
        </div>
      </div>
      <a-alert v-if="role == 0" style="margin: 15px 0" type="info" show-icon>
        <template #message>
          <span style="margin: 0 5px; color: red">{{ selectIds.length }}</span
          >Items Selected
        </template>
      </a-alert>
      <a-table
        :scroll="{ x: 1620 }"
        @resizeColumn="handleResizeColumn"
        :loading="loading"
        rowKey="id"
        :pagination="false"
        :row-selection="
          role == 0
            ? { onChange: onSelectChange, selectedRowKeys: selectIds }
            : null
        "
        :columns="columns"
        :data-source="tableData"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
            <div style="width: 185px; text-align: left">
              <a @click="getSelByUserProcess(record)">Call Record</a>
              <a-divider type="vertical" />
              <a @click="getDetails(record)">Details</a>
              <a-divider type="vertical" />
              <a @click="EditInfo(record)">Edit</a>
            </div>
            <div style="width: 185px; text-align: left">
              <a v-if="record.followUpTheEnd == 0" @click="followUp(record)"
                >Follow-up</a
              >
              <a-divider v-if="record.followUpTheEnd == 0" type="vertical" />
              <a @click="markTags(record)">Mark Tags</a>
            </div>
          </template>
          <template v-else-if="column.key === 'UserName'">
            <div>{{ `${record.firstName} ${record.lastName}` }}</div>
          </template>
          <template v-else-if="column.key === 'tags'">
            <div v-if="record.callCenterFlags.length != 0">
              <a-tag
                v-for="(flag,index) in record.callCenterFlags"
                :color="flag.color"
                :key="index">{{ flag.name }}</a-tag
              >
            </div>
            <div v-else>--</div>
          </template>
          <template v-else-if="column.key === 'companyName'">
            <a-tooltip placement="topLeft">
              <template #title>{{ record.companyName }}</template>
              {{ record.companyName }}
            </a-tooltip>
          </template>
          <template v-else-if="column.key === 'dataDesc'">
            <a-tooltip v-if="record.dataDesc" placement="topLeft">
              <template #title>{{ record.dataDesc }}</template>
              {{ record.dataDesc }}
            </a-tooltip>
            <div v-else>--</div>
          </template>
          <template v-else-if="column.key === 'contact'">
            <div v-if="record.phone">
              <div v-if="record.phone.indexOf('+') > -1">
                Mobile Number：{{ record.phone }}
              </div>
              <div v-else>
                Mobile Number：{{ `+${record.areaCode}${record.phone}` }}
              </div>
            </div>
            <div v-else>
              <div>Mobile Number：{{ '--' }}</div>
            </div>
            <div>Email：{{ record.email || '--' }}</div>
          </template>
          <template v-else-if="column.key === 'ExhibitionName'">
            Title：<a-tooltip placement="topLeft">
              <template #title>{{ record.exhibitionNameEn }}</template>
              {{ record.exhibitionNameEn }}
            </a-tooltip>
            <br />
            Subtitle：<a-tooltip placement="topLeft">
              <template #title>{{ record.subtitleEn }}</template>
              {{ record.subtitleEn }}
            </a-tooltip>
          </template>
          <template v-else-if="column.key === 'country'">
            <div>{{ handleCountry(record.areaCode) }}</div>
          </template>
          <template v-else-if="column.key === 'information'">
            <div>
              Data Source：{{
                record.sourceType == 1 ? 'Imported' : 'Platform'
              }}
            </div>
            <div>
              Client Affiliation：{{
                `${record.belongFirst} ${record.belongLastName}`
              }}
            </div>
            <div>Call Count：{{ record.callCount }}</div>
          </template>
          <template v-else-if="column.key === 'registeredFlag'">
            <div>{{ record.registeredFlag ? 'Yes' : 'No' }}</div>
          </template>
          <template v-else-if="column.key === 'validFlag'">
            <div>{{ record.validFlag ? 'Yes' : 'No' }}</div>
          </template>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :show-total="(total) => `Total ${total} items`"
          v-model:current="searchForm.current"
          v-model:pageSize="searchForm.pageSize"
          show-quick-jumper
          :total="total"
          @change="onCurrentChange"
        />
      </div>
    </div>
    <a-modal
      @ok="handleOk"
      @cancel="close"
      :visible="visible"
      title="Follow-up"
      width="700px"
    >
      <a-form :model="formState" ref="formStateRef" autoComplete="off">
        <p class="title-line">Client Basic Info</p>
        <div class="sTop">
          <a-form-item
            style="width: 50%"
            label="Whether Interested in the Platform"
            name="platformInterestFlag"
          >
            <a-switch
              :checkedValue="1"
              :unCheckedValue="0"
              v-model:checked="formState.platformInterestFlag"
            >
              <template #checkedChildren>
                <check-outlined />
              </template>
              <template #unCheckedChildren>
                <close-outlined />
              </template>
            </a-switch>
          </a-form-item>
          <a-form-item
            style="width: 50%"
            label="Whether Interested in the Exhibition"
            name="exhibitionInterestFlag"
          >
            <a-switch
              :checkedValue="1"
              :unCheckedValue="0"
              v-model:checked="formState.exhibitionInterestFlag"
            >
              <template #checkedChildren>
                <check-outlined />
              </template>
              <template #unCheckedChildren>
                <close-outlined />
              </template>
            </a-switch>
          </a-form-item>
          <a-form-item style="width: 50%" label="Dead Number" name="validFlag">
            <a-switch
              :checkedValue="1"
              :unCheckedValue="0"
              v-model:checked="formState.validFlag"
            >
              <template #checkedChildren>
                <check-outlined />
              </template>
              <template #unCheckedChildren>
                <close-outlined />
              </template>
            </a-switch>
          </a-form-item>
          <a-form-item
            style="width: 50%"
            label="Number Belongs to Buyer Himself"
            name="selfFlag"
          >
            <a-switch
              :checkedValue="1"
              :unCheckedValue="0"
              v-model:checked="formState.selfFlag"
            >
              <template #checkedChildren>
                <check-outlined />
              </template>
              <template #unCheckedChildren>
                <close-outlined />
              </template>
            </a-switch>
          </a-form-item>
          <a-form-item
            style="width: 50%"
            label="Continue Follow-up"
            name="selfFlag"
          >
            <a-switch
              :checkedValue="1"
              :unCheckedValue="0"
              v-model:checked="formState.toFollowUpAgain"
            >
              <template #checkedChildren>
                <check-outlined />
              </template>
              <template #unCheckedChildren>
                <close-outlined />
              </template>
            </a-switch>
          </a-form-item>
          <a-form-item style="width: 50%" label="End Follow-up" name="selfFlag">
            <a-popconfirm
              :disabled="popconfirmDis && formState.followUpTheEnd == 1"
              @cancel="formState.followUpTheEnd == 0"
              @confirm="confirmOpen"
              title="Are you sure to end follow-up ?"
              ok-text="Yes"
              cancel-text="No"
            >
              <a-switch
                @change="handleChange"
                :checked="switchChecked"
                :checkedValue="1"
                :unCheckedValue="0"
              >
                <template #checkedChildren>
                  <check-outlined />
                </template>
                <template #unCheckedChildren>
                  <close-outlined />
                </template>
              </a-switch>
            </a-popconfirm>
          </a-form-item>
        </div>
        <p class="title-line">Buying Policymaker</p>
        <div class="padding">
          <a-form-item
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 20 }"
            label="Buying Policymaker"
            name="purchaseFlag"
          >
            <a-select
              v-model:value="formState.purchaseFlag"
              placeholder="Please Select"
              allowClear
            >
              <a-select-option :value="1">Yes</a-select-option>
              <a-select-option :value="0">No</a-select-option>
              <a-select-option :value="2">Unknown</a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <p class="title-line">Call Record</p>
        <div class="padding">
          <a-form-item
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 20 }"
            label="Call Results"
            name="resultType"
          >
            <a-select
              v-model:value="formState.resultType"
              placeholder="Please Select"
              allowClear
            >
              <a-select-option :value="0">Connected</a-select-option>
              <a-select-option :value="1">Busy</a-select-option>
              <a-select-option :value="2">Dead Number</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 20 }"
            label="Remarks"
            name="remark"
          >
            <a-textarea
              v-model:value="formState.remark"
              placeholder="Please Enter"
            />
          </a-form-item>
        </div>
      </a-form>
    </a-modal>
    <a-modal
      :bodyStyle="{ 'max-height': '600px', 'overflow-y': 'scroll' }"
      :footer="null"
      @cancel="processVisible = false"
      :visible="processVisible"
      title="Call Record"
    >
      <a-empty v-if="processList.length == 0" />
      <a-timeline v-else>
        <a-timeline-item v-for="(item,index) in processList" :key="index">
          <template #dot>
            <history-outlined />
          </template>
          <div>Call Date：{{ item.createdTime }}</div>
          <div>Call Account：{{ `${item.firstName} ${item.lastName}` }}</div>
          <div>
            Call Results：
            <a-tag v-if="item.resultType == 0" color="success">Connected</a-tag>
            <a-tag v-if="item.resultType == 1" color="warning">Busy</a-tag>
            <a-tag v-if="item.resultType == 2" color="default"
              >Dead Number</a-tag
            >
          </div>
          <div>Call Remarks：{{ item.remark || '--' }}</div>
        </a-timeline-item>
      </a-timeline>
    </a-modal>
    <!-- 分配客户model -->
    <a-modal
      @ok="accountOk(1)"
      @cancel="cancelAccount"
      :visible="accountVisible"
      title="Assign Client"
    >
      <a-form style="padding: 30px 20px">
        <a-form-item label="Client Affiliation">
          <a-select
            v-model:value="accountId"
            placeholder="Please Select"
            allowClear
          >
            <a-select-option
              v-for="item in accountList"
              :key="item.id"
              :value="item.id"
              >{{ `${item.firstName} ${item.lastName}` }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 打标客户model -->
    <a-modal
      @ok="addFlag"
      @cancel="cancelMark"
      :visible="markVisible"
      title="Mark tags"
    >
      <a-form style="padding: 30px 20px">
        <a-form-item label="Tags" name="Tags">
          <a-select
            mode="multiple"
            v-model:value="flagIds"
            placeholder="Please Select"
            allowClear
          >
            <a-select-option
              v-for="item in tagsList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      :visible="isImport"
      title="Import"
      width="600px"
      okText="Import"
      @cancel="cancelImport"
    >
      <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="Select Exhibition">
          <a-select
            v-model:value="exhibitionTaskId"
            placeholder="Please Select"
            allowClear
          >
            <a-select-option
              v-for="item in exhibitionTaskList"
              :key="item.id"
              :value="item.id"
              >{{ item.exhibitionNameEn }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Select File">
          <a-upload
            name="file"
            :file-list="fileList"
            :remove="handleRemove"
            :before-upload="beforeUpload"
          >
            <a-button v-if="!fileList.length">
              <upload-outlined></upload-outlined> Select File
            </a-button>
          </a-upload>
        </a-form-item>
      </a-form>
      <div class="errorMsg" v-if="errorMsg != ''">
        <p>{{ errorMsg }}</p>
      </div>
      <template #footer>
        <a-button @click="cancelImport">Cancel</a-button>
        <a-button type="primary" @click="handleImport" :loading="btn_loading"
          >Import</a-button
        >
      </template>
    </a-modal>
    <!-- 查看详情弹框 -->
    <a-modal
      title="Client Details"
      :visible="InfoVisible"
      @cancel="InfoVisible = false"
      :footer="null"
      width="800px"
    >
      <a-descriptions style="padding: 0px 20px 40px" :column="2">
        <a-descriptions-item label="Company Name" :span="3">
          {{ customerInfo.companyName || '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="Country Code">
          {{ customerInfo.areaCode || '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="Email Address">
          {{ customerInfo.email || '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="Mobile Number">
          {{ customerInfo.phone || '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="Country/Region">
          {{ customerInfo.countryNameEn || '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="Client Name">
          {{ `${customerInfo.firstName} ${customerInfo.lastName}` }}
        </a-descriptions-item>
        <a-descriptions-item label="Number Belongs to Buyer Himself">
          <a-tag
            v-if="customerInfo && customerInfo.selfFlag == 1"
            color="success"
            >Yes</a-tag
          >
          <a-tag v-else color="default">No</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Dead Number">
          <a-tag
            v-if="customerInfo && customerInfo.validFlag == 1"
            color="success"
            >Yes</a-tag
          >
          <a-tag v-else color="default">No</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Followed Up">
          <a-tag
            v-if="customerInfo && customerInfo.isFollowUp > 0"
            color="success"
            >Yes</a-tag
          >
          <a-tag v-else color="default">No</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Whether Interested In The Platform">
          <a-tag
            v-if="customerInfo && customerInfo.platformInterestFlag == 1"
            color="success"
            >Yes</a-tag
          >
          <a-tag v-else color="default">No</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Whether Interested In The Exhibition">
          <a-tag
            v-if="customerInfo && customerInfo.exhibitionInterestFlag == 1"
            color="success"
            >Yes</a-tag
          >
          <a-tag v-else color="default">No</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Continue Follow-up">
          <a-tag
            v-if="customerInfo && customerInfo.toFollowUpAgain == 1"
            color="success"
            >Yes</a-tag
          >
          <a-tag v-else color="default">No</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="End Follow-up">
          <a-tag
            v-if="customerInfo && customerInfo.followUpTheEnd == 1"
            color="success"
            >Yes</a-tag
          >
          <a-tag v-else color="default">No</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Data Source">
          <a-tag
            v-if="customerInfo && customerInfo.sourceType == 1"
            color="#87d068"
            >Imported</a-tag
          >
          <a-tag v-else color="#87d068">Platform</a-tag>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
    <a-modal
      width="550px"
      :visible="editModal"
      title="Edit Client"
      @cancel="closeEditModal"
    >
      <a-form
        :model="submitForm"
        ref="submitFormRef"
        :rules="rules"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 18 }"
      >
        <a-form-item label="Country/Region" name="country">
          <a-select
            optionFilterProp="option"
            show-search
            :filter-option="filterOption"
            v-model:value="submitForm.country"
            placeholder="Please Select"
            allowClear
          >
            <a-select-option
              v-for="item in stateList"
              :option="item.englishName"
              :key="item.id"
              :value="item.englishName"
              >{{ item.englishName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Company Name" name="companyName">
          <a-input
            v-model:value="submitForm.companyName"
            placeholder="Company Name"
          />
        </a-form-item>
        <a-form-item
          label="Full Name"
          :rules="[{ required: true }]"
          style="display: flex; margin: 0"
        >
          <a-form-item
            name="first Name"
            style="display: inline-block; width: 182px"
          >
            <a-input
              v-model:value="submitForm.firstName"
              placeholder="First Name"
            /> </a-form-item
          >&ensp;
          <a-form-item
            name="Last Name"
            style="display: inline-block; width: 183px"
          >
            <a-input
              v-model:value="submitForm.lastName"
              placeholder="Last Name"
            />
          </a-form-item>
        </a-form-item>
        <a-form-item label="Mobile Number" name="phone">
          <a-input
            v-model:value="submitForm.phone"
            placeholder="Mobile Number"
          />
        </a-form-item>
        <a-form-item label="Email" name="email">
          <a-input
            v-model:value="submitForm.email"
            placeholder="Email"
          />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button @click="closeEditModal">Cancel</a-button>
        <a-button type="primary" @click="submit">Edit</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
// vue3的写法
import {
  getTagsAll,
  getTags,
  addCustomerFlag,
  selectCallCenterCustomerFlag,
} from '@/api/tags'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { watch, reactive, ref, toRefs } from 'vue'
import axios from 'axios'
import { setTheLocalStore } from '@/utils/setTheLocalStore'
import {
  CheckOutlined,
  CloseOutlined,
  HistoryOutlined,
  UploadOutlined,
  DownOutlined,
  UpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons-vue'
import {
  getCustoms,
  saveCustomsProcessor,
  selByUserProcess,
  getSonAccountLists,
  distCustoms,
  getStateInfo,
  getExhibitionTaskLists,
  checkAll,
  getCenterTaskCustomerDetail,
  editCustomsInfo,
  getDataDesc,
} from '@/api/customer'

const columns = [
  {
    title: 'Company Name',
    key: 'companyName',
    ellipsis: true,
    width: 170,
  },
  {
    title: 'Client Name',
    key: 'UserName',
    width: 170,
  },
  {
    title: 'Client Information',
    key: 'contact',
    width: 270,
  },
  {
    title: 'Exhibitions',
    key: 'ExhibitionName',
    width: 170,
    ellipsis: true,
  },
  {
    title: 'Country/Region',
    key: 'country',
    width: 130,
  },
  {
    title: 'Data Description',
    key: 'dataDesc',
    width: 130,
  },
  {
    title: 'Tags',
    key: 'tags',
    width: 150,
  },
  {
    title: 'Data Source',
    key: 'information',
    width: 180,
  },
  {
    title: 'Registered',
    key: 'registeredFlag',
    width: 100,
    align: 'center',
  },
  {
    title: 'Dead Number',
    key: 'validFlag',
    width: 120,
    align: 'center',
  },

  {
    title: 'Operate',
    key: 'action',
    width: 195,
    align: 'center',
    fixed: 'right',
  },
]
export default {
  components: {
    CheckOutlined,
    CloseOutlined,
    HistoryOutlined,
    UploadOutlined,
    DownOutlined,
    UpOutlined,
    CaretDownOutlined,
  },
  setup() {
    const data = reactive({
      dataDescList: [],
      flagIds: [],
      markVisible: false,
      customerId: undefined,
      tagsList: [],
      tagsListAll: [],
      editModal: false,
      popconfirmDis: false,
      switchChecked: 0,
      switchLoading: false,
      isOpen: false,
      InfoVisible: false,
      customerInfo: {},
      resetLoading: false,
      importUrl:
        'http://image.matchupexpo.com/CallCenter buyers import template.xlsx',
      exhibitionTaskId: undefined,
      token: undefined,
      btn_loading: false,
      errorMsg: '',
      fileList: [],
      isImport: false,
      loading: false,
      stateList: [],
      exhibitionTaskList: [],
      userData: null,
      accountId: undefined,
      role: 0,
      userId: undefined,
      visible: false,
      processVisible: false,
      accountVisible: false,
      formState: {
        platformInterestFlag: 0,
        exhibitionInterestFlag: 0,
        toFollowUpAgain: 0,
        followUpTheEnd: 0,
        purchaseFlag: 0,
        validFlag: 0,
        selfFlag: 0,
        resultType: 0,
        talkTime: undefined,
        remark: '',
        customerId: undefined,
      },
      submitForm: {
        id: undefined,
        email: undefined, //邮箱
        firstName: undefined, //姓
        lastName: undefined, //名
        companyName: undefined, //公司名
        phone: undefined, //手机号
        country: undefined, //国家
      },
      columns,
      tableData: [],
      total: 0,
      searchForm: {
        callCountMin:undefined,
        callCountMax:undefined,
        flagIds: undefined,
        exhibitionTaskId: undefined,
        belongUserId: undefined,
        current: 1,
        pageSize: 10,
        user: undefined,
        companyName: '',
        phone: '',
        validFlag: undefined,
        selfFlag: undefined,
        isFollowUp: undefined,
        startTime: undefined,
        endTime: undefined,
        registeredFlag: undefined,
        followUpStatus: undefined,
        endTime: undefined,
        timeRange: undefined,
      },
      selectIds: [],
      processList: [],
      accountList: [],
      rules: {
        // email: [{ required: true, message: 'Can not be empty', trigger: 'blur' }],
        firstName: [
          { required: true, message: 'Can not be empty', trigger: 'blur' },
        ],
        lastName: [
          { required: true, message: 'Can not be empty', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: 'Can not be empty', trigger: 'blur' },
        ],
        companyName: [
          { required: true, message: 'Can not be empty', trigger: 'blur' },
        ],
        country: [
          { required: true, message: 'Please Select', trigger: 'change' },
        ],
      },
    })
    const submitFormRef = ref()
    const formStateRef = ref()
    const searchFormRef = ref()
    const router = useRouter()
    data.userData = setTheLocalStore.getLocalStore(
      'userData',
      'localStorage'
    ) || { role: 0 }
    data.token = setTheLocalStore.getLocalStore('token', 'localStorage')
    data.role = data.userData.role
    // 监听属性
    watch(
      () => data.switchChecked,
      (newValue, oldValue) => {
        if (newValue) {
          data.popconfirmDis = true
        } else {
          data.popconfirmDis = false
        }
      },
      { immediate: true }
    )
    //获取启用所有标签
    function getTagList() {
      getTags().then((res) => {
        data.tagsList = res.data
      })
    }
    function getAllData() {
      data.searchForm.current = 1
      if (data.searchForm.callCountMin &&data.searchForm.callCountMax) {
        if (data.searchForm.callCountMin<=data.searchForm.callCountMax) {
         getData()
        }else{
        return  message.error('The minimum value cannot be greater than the maximum value')
        }
      }
      getData()
      
    }
    //获取所有数据描述
    function getAllDataDesc() {
      getDataDesc().then((res) => {
        data.dataDescList = res.data
      })
    }
    //获取所有标签
    function getTagAllList() {
      getTagsAll().then((res) => {
        data.tagsListAll = res.data
      })
    }
    //获取所有子账号
    function sonAccountLists() {
      getSonAccountLists({ associationId: data.userData.associationId }).then(
        (res) => {
          data.accountList = res.data
        }
      )
    }
    //获取所有国家
    function stateInfo() {
      getStateInfo().then((res) => {
        data.stateList = res.data
      })
    }
    //获取所有展会任务
    function exhibitionTaskLists() {
      getExhibitionTaskLists().then((res) => {
        data.exhibitionTaskList = res.data
      })
    }
    //确认编辑
    function submit() {
      submitFormRef.value.validate().then((value) => {
        data.submitForm = { ...value, id: data.submitForm.id }
        editCustomsInfo(data.submitForm).then((res) => {
          message.success('Success')
          closeEditModal()
          getData()
        })
      })
    }
    // 关闭编辑弹框
    function closeEditModal() {
      data.editModal = false
      submitFormRef.value && submitFormRef.value.resetFields()
      data.submitForm = {
        id: undefined,
        email: undefined, //邮箱
        firstName: undefined, //姓
        lastName: undefined, //名
        phone: undefined, //手机号
        companyName: undefined,
        country: undefined, //国家
      }
    }
    // 点击编辑，获取数据，回填表单
    function EditInfo(record) {
      getCenterTaskCustomerDetail({ id: record.id }).then((res) => {
        data.submitForm.email = res.data.email
        data.submitForm.firstName = res.data.firstName
        data.submitForm.lastName = res.data.lastName
        data.submitForm.phone = res.data.phone
        data.submitForm.companyName = res.data.companyName
        data.submitForm.country = res.data.countryNameEn
        data.submitForm.id = record.id
        data.editModal = true
      })
    }
    // 表格列宽改变
    function handleResizeColumn(w, col) {
      col.width = w
    }
    // 确认结束跟进开关的change
    function handleChange(checked) {
      data.switchChecked = !checked
      data.formState.followUpTheEnd = checked
    }
    // 确认结束跟进确认框
    function confirmOpen() {
      data.switchChecked = 1
      data.formState.followUpTheEnd = 1
    }
    // 处理时间
    function handleDate(_, m) {
      data.searchForm.startTime = m[0]
      data.searchForm.endTime = m[1]
    }
    // 点击分配账号
    function showAccountVisible() {
      if (data.accountList.length > 0) {
        data.accountId = data.accountList[0].id
      }
      data.accountVisible = true
    }
    // 国家搜索
    function filterOption(value, option) {
      return option.option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    }
    // 打开打标弹窗
    function markTags(record) {
      data.customerId = record.id
      selectCallCenterCustomerFlag({ customerId: record.id }).then((res) => {
        data.flagIds = res.data.map((el) => {
          return el.id
        })
        data.markVisible = true
      })
    }
    // 点击跟进，获取数据，回填数据
    function followUp(record) {
      data.formState.customerId = record.id
      getCenterTaskCustomerDetail({ id: record.id }).then((res) => {
        data.formState.platformInterestFlag = res.data.platformInterestFlag
        data.formState.exhibitionInterestFlag = res.data.exhibitionInterestFlag
        data.formState.validFlag = res.data.validFlag
        data.formState.selfFlag = res.data.selfFlag
        data.formState.toFollowUpAgain = res.data.toFollowUpAgain
        data.formState.purchaseFlag = res.data.purchaseFlag
        data.switchChecked = res.data.followUpTheEnd
        data.visible = true
      })
    }
    // 跟进id获取详情
    function getDetails(record) {
      getCenterTaskCustomerDetail({ id: record.id }).then((res) => {
        data.customerInfo = res.data
        data.InfoVisible = true
      })
    }
    // 全选列表
    function checkAllData() {
      data.resetLoading = true
      checkAll(data.searchForm)
        .then((res) => {
          data.selectIds = res.data
          data.resetLoading = false
        })
        .catch((err) => {
          data.resetLoading = false
        })
    }
    // 处理国家显示
    function handleCountry(value) {
      if (value) {
        let option = data.stateList.find((el) => el.areaCode == value) || {}
        return option.englishName
      }
      return '--'
    }
    // 确认导入
    async function handleImport() {
      if (data.exhibitionTaskId == undefined) {
        message.warning('Please select exhibition')
        return false
      }
      if (!data.fileList.length) {
        message.warning('Please select file')
        return false
      }
      const formData = new FormData()
      data.fileList.forEach((file) => {
        formData.append('file', file)
      })
      data.btn_loading = true
      const { data: result } = await axios({
        url:
          process.env.VUE_APP_BASEURL +
          '/callcenter/callCenterTaskCustomer/taskCustomsImport',
        method: 'post',
        headers: { Accept: 'application/json', 'Auth-Token': data.token },
        data: formData,
        params: { exhibitionTaskId: data.exhibitionTaskId },
      })
      if (result.code !== 200) {
        let messageText = result.message ? result.message.split('|') : undefined
        data.errorMsg = messageText[1] || 'Error'
        data.btn_loading = false
      } else {
        data.errorMsg = ''
        data.btn_loading = false
        message.success('Success')
        data.isImport = false
        getData()
      }
    }
    // 移除文件的回调
    function handleRemove(file) {
      const index = data.fileList.indexOf(file)
      const newFileList = data.fileList.slice()
      newFileList.splice(index, 1)
      data.fileList = newFileList
    }
    //上传之前回调
    function beforeUpload(file) {
      const isJpgOrPng =
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      if (!isJpgOrPng) {
        message.error('Only Excel files can be uploaded!')
      }
      const isLt2M = file.size / 1024 / 1024 < 100
      if (!isLt2M) {
        message.error('File size cannot exceed 100m!')
      }
      if (isJpgOrPng && isLt2M) {
        data.fileList = [...data.fileList, file]
        return isJpgOrPng && isLt2M && data.fileList && false
      } else {
        return
      }
    }
    //取消导入
    function cancelImport() {
      data.exhibitionTaskId = undefined
      data.isImport = false
      data.fileList = []
      data.errorMsg = ''
    }
    //根据id获取通话记录
    function getSelByUserProcess(record) {
      data.processVisible = true
      selByUserProcess({ customsId: record.id }).then((res) => {
        data.processList = res.data
      })
    }
    //确认跟进
    function handleOk() {
      if (data.switchChecked === 1) {
        data.formState.followUpTheEnd = 1
      } else {
        data.formState.followUpTheEnd = 0
      }
      saveCustomsProcessor(data.formState).then((res) => {
        message.success('Success')
        getData()
        close()
      })
    }
    //确认分配客户
    function accountOk(type) {
      if (type == 1) {
        if (!data.accountId) {
          return message.warning('Please select sub-account')
        }
        distCustoms({
          accountId: data.accountId,
          customsId: data.selectIds,
        }).then((res) => {
          message.success('Success')
          getData()
          data.selectIds = []
          data.accountVisible = false
        })
      } else {
        distCustoms({
          accountId: data.userData.id,
          customsId: data.selectIds,
        }).then((res) => {
          message.success('Success')
          getData()
          data.selectIds = []
          data.accountVisible = false
        })
      }
    }
    function addFlag() {
      addCustomerFlag({ customerId: data.customerId, flagIds: data.flagIds })
        .then((res) => {
          message.success('Success')
          getData()
          cancelMark()
        })
        .catch((err) => {
          getData()
          cancelMark()
        })
    }
    function cancelMark() {
      data.customerId = undefined
      data.markVisible = false
    }
    //关闭跟进弹窗
    function close() {
      data.visible = false
      formStateRef.value && formStateRef.value.resetFields()
    }
    //重置
    function reset() {
      searchFormRef.value && searchFormRef.value.resetFields()
      data.searchForm.startTime = undefined
      data.searchForm.endTime = undefined
      data.searchForm.flagIds = undefined
      getData()
    }
    //获取表格数据
    function getData() {
      data.loading = true
      getCustoms(data.searchForm)
        .then((res) => {
          data.tableData = res.data.records
          data.tableData.forEach((el) => {
            el.id = Number(el.id)
          })
          data.total = res.data.total
          data.loading = false
          data.selectIds = []
        })
        .catch((err) => {
          data.loading = false
          data.tableData = []
        })
    }
    //分页
    function onCurrentChange(pageNumber) {
      data.searchForm.current = pageNumber
      getData()
    }
    //勾选表格
    function onSelectChange(selectedRowKeys) {
      data.selectIds = selectedRowKeys
    }
    //获取所有子账号
    sonAccountLists()
    //获取所有国家
    stateInfo()
    //获取所有展会任务
    exhibitionTaskLists()
    //获取表格数据
    getData()
    //获取tags（启用）
    getTagList()
    //获取tags（所有）
    getTagAllList()
    //获取所有数据描述
    getAllDataDesc()
    function cancelAccount() {
      data.accountVisible = false
      data.accountId = undefined
    }
    return {
      ...toRefs(data), //在模板上不用写data.
      submitFormRef,
      formStateRef,
      searchFormRef,
      submit,
      closeEditModal,
      EditInfo,
      handleResizeColumn,
      handleChange,
      confirmOpen,
      handleDate,
      showAccountVisible,
      filterOption,
      markTags,
      cancelMark,
      followUp,
      getDetails,
      checkAllData,
      handleCountry,
      handleImport,
      handleRemove,
      beforeUpload,
      cancelImport,
      getSelByUserProcess,
      handleOk,
      accountOk,
      close,
      reset,
      getData,
      onCurrentChange,
      onSelectChange,
      cancelAccount,
      addFlag,
      getAllData,
    }
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/cc-list-tap-container.less';
@import '../../assets/cc-search-wrap.less';
.pagination {
  margin-top: 30px;
  text-align: right;
}
.assign {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  margin-bottom: 10px;
}
.cc-list-content {
  .cc-search-wrap {
    :deep(.ant-form) {
      .ant-form-item {
        width: 480px;
        .ant-form-item-label {
          width: 160px;
        }
      }
    }
  }
}
.title-line {
  height: 20px;
  padding-left: 10px;
  line-height: 20px;
  font-size: 14px;
  position: relative;
  font-weight: bold;
  margin-bottom: 20px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 20px;
    background-color: #1890ff;
  }
}
.sTop {
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
}
.padding {
  padding-left: 15px;
}
.modal-style {
  padding: 10px 30px;
  display: flex;
  align-items: center;
}
.errorMsg {
  margin-top: 5px;
  color: #f00;
  padding: 0 30px;
  font-size: 16px;
}
</style>
